import React from "react"

import { AddOnsI } from "../../interface"
import BookingDetailsAddOnsCard from "../BookingDetailsAddOnsCard/BookingDetailsAddOnsCard"
import "./BookingDetails.scss"

interface Props {
  addOnsList: AddOnsI[]
  setAddOnsList: React.Dispatch<React.SetStateAction<any[]>>
}

const AddOns: React.FC<Props> = ({ addOnsList, setAddOnsList }) => {
  const addOrMinus = (index, cnt, operation) => {
    const newAddOns = [...addOnsList]

    if (operation === "add") {
      newAddOns[index].counter = cnt + 1
    } else if (operation === "minus") {
      newAddOns[index].counter = cnt - 1
    }

    return setAddOnsList(newAddOns)
  }

  const handleAdd = key => {
    addOnsList.map((item, index) => {
      if (index === key) {
        return (item.selected = !item.selected)
      }
    })
    setAddOnsList([...addOnsList])
  }

  return (
    <>
      <div className="add-ons-wrapper">
        <h5 className="add-ons-title">
          Customize your stay with our add-ons! <span>(Optional)</span>
        </h5>

        <div className="add-ons-items-wrapper">
          {addOnsList &&
            addOnsList.map((item, i) => (
              <BookingDetailsAddOnsCard
                key={i}
                keyIndex={i}
                title={item.name}
                description={item.description}
                price={item.net_rates}
                taxCharge={item.tax}
                cardImage={item.images[0]?.image_paths}
                counter={item.counter}
                addOrMinus={addOrMinus}
                handleAdd={handleAdd}
                selected={item.selected}
              />
            ))}
        </div>
      </div>
    </>
  )
}

export default AddOns
