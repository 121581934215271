import React from "react"
import { Col, Modal, Row, Tabs } from "antd"

import "../BookingDetails.scss"
import paymentIcon from "../../../assets/images/ic_payments.svg"
import hotelIcon from "../../../assets/images/ic_apartment.svg"

const { TabPane } = Tabs

interface PropsI {
  paymentOptions: any
  rateDetails: any
  roomDetails: any
  visible: boolean
  onCancel?: () => void
}

const RateDetails = ({
  paymentOptions,
  rateDetails,
  roomDetails,
  visible,
  onCancel,
}: PropsI) => {
  const { inclusion } = rateDetails?.rate_plans[0]
  const termsAndConditions = roomDetails?.terms_and_conditions
  const packageTermsCondition =
    rateDetails?.rate_plans[0].pkg_terms_condition?.text
  const categoryInclusions = roomDetails?.category_inclusions
  const generalInclusions = roomDetails?.general_inclusions
  const packageInclusions = inclusion?.text
  const checkOverrideTC = packageTermsCondition?.override_terms_condition
  const checkOverrideInclusions = inclusion?.override_inclusion

  const payOnline =
    paymentOptions?.status === "ENABLED" ? (
      <Col span={11} className="rate-payment-option">
        <img
          className="rate-payment-icon"
          src={paymentIcon}
          alt="payment icon"
        />{" "}
        <span>Pay Online</span>
      </Col>
    ) : null

  const payAtHotel =
    paymentOptions?.pay_at_hotel === "ENABLED" ? (
      <Col span={11} className="rate-payment-option">
        <img className="rate-payment-icon" src={hotelIcon} alt="hotel icon" />{" "}
        <span>Pay at Hotel</span>
      </Col>
    ) : null

  const details = () => {
    const regularInclusions = (
      <>
        <div
          className="inclusions"
          dangerouslySetInnerHTML={{ __html: generalInclusions }}
        />
        <div
          className="inclusions"
          dangerouslySetInnerHTML={{ __html: categoryInclusions }}
        />
      </>
    )

    const renderRegularInclusions = !checkOverrideInclusions
      ? regularInclusions
      : null

    return (
      <div className="rate-details-container">
        <h3>Description</h3>
        <p style={{ marginBottom: 0 }}>
          {rateDetails?.rate_plans[0].description}
        </p>
        <h3 className="rate-header">Amenities & Inclusions</h3>
        {renderRegularInclusions}
        <div
          className="inclusions"
          dangerouslySetInnerHTML={{ __html: packageInclusions }}
        />
        <h3 className="rate-header">Applicable payment options</h3>
        <Row justify="space-around">
          {payOnline}
          {payAtHotel}
        </Row>
      </div>
    )
  }

  const showTermsAndConditions = !checkOverrideTC ? (
    <>
      <div
        className="overview-description"
        dangerouslySetInnerHTML={{ __html: termsAndConditions }}
      />
    </>
  ) : null

  const showPackageTC = packageTermsCondition ? (
    <>
      <h3 className="rate-header">Package Terms & Conditions</h3>
      <div
        className="overview-description"
        dangerouslySetInnerHTML={{ __html: packageTermsCondition }}
      />
    </>
  ) : null

  return (
    <Modal
      className="hotel-details-modal-wrapper"
      visible={visible}
      footer={false}
      onCancel={onCancel}
    >
      <h1 className="hotel-name">{rateDetails?.rate_plans[0].name}</h1>
      <Tabs defaultActiveKey="1">
        <TabPane tab="Details" key="1">
          {details()}
        </TabPane>
        <TabPane tab="Terms and Conditions" key="2">
          {showTermsAndConditions}
          {showPackageTC}
        </TabPane>
      </Tabs>
    </Modal>
  )
}

export default RateDetails
