import React, { useState } from "react"
import { Button } from "antd"
import { CheckCircleFilled } from "@ant-design/icons"

import RateDetails from "./Modals/RateDetails"
import { currencyFormatter } from "../../utilities/format-currency"

const RateSummary = ({
  handleClick,
  isDisplayed,
  paymentOptions,
  priceData,
  rateDetails,
  rateIndex,
  roomDetails,
  selected,
}) => {
  const [showRateDetails, setShowRateDetails] = useState<boolean>(false)
  const amount = priceData[rateIndex]?.totalPricePerNight

  const primaryBtn = (index, isDisplayed) => (
    <Button
      type="primary"
      className="primary-btn"
      onClick={() => handleClick(index, true, rateDetails, isDisplayed)}
    >
      Select
    </Button>
  )

  const selectedBtn = index => (
    <Button className="selected-btn" onClick={() => handleClick(index, false)}>
      Selected
    </Button>
  )

  const showCheck = selected ? (
    <CheckCircleFilled className="green-check" />
  ) : null

  const renderViewDetailsButton = rateDetails ? (
    <Button
      type="text"
      className="txt-danger-btn"
      onClick={() => setShowRateDetails(true)}
    >
      View Details
    </Button>
  ) : null

  const renderRateDetails = rateDetails ? (
    <RateDetails
      paymentOptions={paymentOptions}
      roomDetails={roomDetails}
      rateDetails={rateDetails}
      visible={showRateDetails}
      onCancel={() => setShowRateDetails(false)}
    />
  ) : null

  return (
    <>
      <div className="booking-summary-details-cont booking-mobile-view">
        <div className="main-collapsible-header-details-wrapper">
          <div>
            <div>
              <h5 className="booking-things">
                {rateDetails?.rate_plans[0].name}
              </h5>
              {showCheck}
            </div>
            <div>{renderViewDetailsButton}</div>
          </div>
          <div>
            <h5 className="price-value">
              <span>
                {currencyFormatter(amount, {
                  significantDigits: 2,
                  symbol: "Php",
                })}
              </span>
            </h5>
            <p className="additional-price">
              +{" "}
              {currencyFormatter(priceData[rateIndex]?.totalTaxPerNight, {
                significantDigits: 2,
                symbol: "Php",
              })}{" "}
              taxes & charges
              <br />/ night
            </p>
          </div>
        </div>
        <div>
          {selected
            ? selectedBtn(rateIndex)
            : primaryBtn(rateIndex, isDisplayed)}
        </div>
      </div>
      {renderRateDetails}
    </>
  )
}

export default RateSummary
