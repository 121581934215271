import styled from "styled-components"
import { Carousel } from "antd"

export const StyledCarousel = styled(Carousel)({
  div: {
    width: "100%",
  },
  img: {
    width: "100%",
  },

  ".ant-carousel .slick-prev, .ant-carousel .slick-next, .ant-carousel .slick-prev:hover,.ant-carousel .slick-next:hover":
    {
      fontSize: "inherit",
      color: "currentColor",
    },

  ".slick-prev, .slick-next": {
    zIndex: 1,
    width: 30,
    height: 30,
    backgroundColor: "#D7272D",
    transition: "opacity 500ms",
    opacity: 0.8,
    ":hover": {
      opacity: 1,
      backgroundColor: "#D7272D",
    },
  },
  ".slick-prev": {
    left: 0,
  },
  ".slick-next": {
    right: 0,
  },
})
