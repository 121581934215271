import React from "react"
import { Button, Col, Row } from "antd"
import { PlusOutlined, MinusOutlined } from "@ant-design/icons"

import "./BookingDetailsAddOnsCard.scss"

import { currencyFormatter } from "../../utilities/format-currency"

interface Props {
  title?: string | React.ReactNode
  description?: string | React.ReactNode
  price?: number
  taxCharge?: number
  cardImage?: string
  counter: number
  addOrMinus?: (index: number, cnt: number, operation: string) => void
  handleAdd?: any
  keyIndex?: number
  selected: boolean
}

const BookingDetailsAddOnsCard: React.FC<Props> = ({
  title,
  description,
  price,
  taxCharge,
  cardImage,
  counter,
  addOrMinus,
  handleAdd,
  keyIndex,
  selected,
}) => {
  const handleCounter = (index, cnt, operation) => {
    addOrMinus && addOrMinus(index, cnt, operation)
  }
  return (
    <Row className="details-card-wrapper">
      <Col xs={21} md={6} xl={6} className="details-card-content-img-wrapper">
        <img src={cardImage} alt="Img" />
      </Col>

      <Col xl={17} md={17} xs={22} className="details-card-content-right-top-wrapper">
   
        <Row justify="space-between">
          <Col xl={15} xs={20} >
          <h5 className="add-ons-card-title">{title}</h5>
          <p className="add-ons-description">{description}</p>
          </Col>
          <Col xs={30}>
          <h5 className="add-ons-card-price">
            {currencyFormatter(price, {
              significantDigits: 2,
              symbol: "Php",
            })}
          </h5>
          <p className="add-ons-additional-price">
            +{" "}
            {currencyFormatter(taxCharge, {
              significantDigits: 2,
              symbol: "Php",
            })}{" "}
            taxes & charges
          </p>
          </Col>

          <Col className="details-card-content-right-bottom-wrapper">
          <Row>
            <Button
              className="normal-btn"
              onClick={() => handleCounter(keyIndex, counter, "minus")}
              disabled={selected ? counter <= 1 : counter <= 0}
            >
              <MinusOutlined />
            </Button>
            <span className="counter">{counter}</span>
            <Button
              className="normal-btn"
              onClick={() => handleCounter(keyIndex, counter, "add")}
            >
              <PlusOutlined />
            </Button>
  
              <Button
                className="primary-btn"
                type={selected ? "default" : "primary"}
                onClick={() => handleAdd(keyIndex)}
                disabled={counter && counter > 0 ? false : true}
              >
                {selected ? "Added" : "Add"}
              </Button>
           </Row>
          </Col>

        </Row>
 
  
    
      </Col>

    </Row>
  )
}

export default BookingDetailsAddOnsCard
