import React from "react"
import { Tabs, Spin } from "antd"
import { CheckCircleFilled } from "@ant-design/icons"

import RoomCard from "./RoomCard"

import noResults from "../../assets/images/noresult.png"
import "./BookingDetails.scss"

const { TabPane } = Tabs

const SelectRooms = ({
  activeRoom,
  loading,
  partyArray,
  paymentOptions,
  rateSelectedOnRooms,
  roomsAndRatesDetails,
  roomDataInquiry,
  setActiveRoom,
  setRateSelectedOnRooms,
}) => {
  const roomTab = event => {
    setActiveRoom(Number(event))
  }

  const roomsAndRates = (roomNumber, roomID) => {
    const roomsAndRatesArray = roomsAndRatesDetails?.map(
      (roomandrate, index) => {
        const ratesList = roomandrate.room_rates.filter(rate => {
          const isRoomIncluded = rate.price_per_room.find(
            item => item.room_id == roomID
          )

          return isRoomIncluded
        })
        const priceData = ratesList?.map(item =>
          item.price_per_room.find(room => room.room_id === roomID)
        )

        if (ratesList.length) {
          return (
            <RoomCard
              key={index}
              paymentOptions={paymentOptions}
              priceData={priceData}
              rateSelectedOnRooms={rateSelectedOnRooms}
              ratesList={ratesList}
              roomandrate={roomandrate}
              roomDataInquiry={roomDataInquiry}
              roomNumber={roomNumber}
              setActiveRoom={setActiveRoom}
              setRateSelectedOnRooms={setRateSelectedOnRooms}
            />
          )
        } else {
          return null
        }
      }
    )

    const noRates = (
      <div className="no-rates">
        <img src={noResults} alt="no rates available" />
        <h2>Snap! No room available</h2>
        <p>
          No room available for selected number of adults.
          <br />
          Try to modify the number of guests each room.
        </p>
      </div>
    )

    const showRoomsAndRates = roomsAndRatesArray.length
      ? roomsAndRatesArray
      : noRates

    return showRoomsAndRates
  }
  const tabPanes = partyArray.map((room, key) => (
    <TabPane
      tab={
        <span className="tabpane">
          Room {key + 1}
          {rateSelectedOnRooms[key] ? (
            <CheckCircleFilled className="green-check-room" />
          ) : null}
        </span>
      }
      key={key + 1}
    >
      {roomsAndRates(key + 1, room.room_id)}
    </TabPane>
  ))

  const noRooms = (
    <div className="no-rates">
      <img src={noResults} alt="no rates available" />
      <h2>No rates available</h2>
      <p>
        No rates available for this hotel.
        <br />
        Try to modify your search.
      </p>
    </div>
  )

  const hasRooms = (
    <>
      <div className="select-room-wrapper">
        <h4 className="select-subtitle">Select a room & rate for:</h4>
        <h4 className="room-subtitle">
          Room {activeRoom} of {partyArray.length}
        </h4>
      </div>
      <div className="booking-summary-tabs-room">
        <Tabs activeKey={`${activeRoom}`} type="card" onChange={roomTab}>
          {tabPanes}
        </Tabs>
      </div>
    </>
  )

  const showRooms =
    roomsAndRatesDetails && roomsAndRatesDetails?.length ? hasRooms : noRooms

  return <Spin spinning={loading}>{showRooms}</Spin>
}

export default SelectRooms
