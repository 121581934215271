import React, { useEffect, useRef, useState } from "react"
import { Button, Collapse, Divider } from "antd"
import { PictureOutlined } from "@ant-design/icons"
import { useQuery } from "@apollo/client"

import RateSummary from "./RateSummary"
import RoomDetails from "./Modals/RoomDetails"
import { ArrowRightIcon } from "../../utilities/icons/icons"
import { ROOM_DETAILS } from "./graphql"

const { Panel } = Collapse

const RoomCard = ({
  paymentOptions,
  priceData,
  rateSelectedOnRooms,
  ratesList,
  roomandrate,
  roomDataInquiry,
  roomNumber,
  setActiveRoom,
  setRateSelectedOnRooms,
}) => {
  const roomRates = priceData.map(item => item.totalPricePerNight)
  const lowestRate = roomRates.indexOf(Math.min.apply(Math, roomRates))

  const [isViewRate, setIsViewRate] = useState<boolean>(true)
  const [roomDetails, setRoomDetails] = useState(null)
  const [roomNameSelected, setRoomNameSelected] = useState<string>("")
  const [rateIndexSelected, setRateIndexSelected] = useState(lowestRate)
  const [showRoomDetails, setShowRoomDetails] = useState<boolean>(false)
  const [rateDisplay, setRateDisplay] = useState(ratesList[rateIndexSelected])
  const [tabKey, setTabKey] = useState("1")
  const toggleRef = useRef<HTMLDivElement | null>(null)

  const roomName = roomandrate.room_name
  const photosArray = roomandrate.photos
  const photoPreview = photosArray[0]
  const isCollapsible = ratesList.length > 1 ? "header" : "disabled"
  const isSelected = roomNameSelected === roomName

  const { data } = useQuery(ROOM_DETAILS, {
    variables: {
      input: {
        ...roomDataInquiry,
        persistent_room_code: roomandrate.persistent_room_code,
      },
    },
  })

  useEffect(() => {
    if (typeof data !== "undefined") {
      setRoomDetails(data.getRoomDetails)
    }
  }, [data])

  useEffect(() => {
    const rateData = rateSelectedOnRooms[roomNumber - 1]

    if (rateData) {
      setRoomNameSelected(rateData.roomName)
    } else {
      setRoomNameSelected("")
    }
  }, [rateSelectedOnRooms])

  const handleClick = (index, toggle, rateDetails, isDisplayed) => {
    if (toggle) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      })
      const amount = priceData[index]?.totalPrice
      const amountWithTax = priceData[index]?.priceWithTax
      const newRateSelected = [...rateSelectedOnRooms]
      const partnerDataID = rateDetails?.partner_data
      const rateName = rateDetails?.rate_plans[0].name
      const serviceCharge = priceData[index]?.tax_breakdown[0].service_charge
      const tax = priceData[index]?.totalTax
      const vat = priceData[index]?.tax_breakdown[0].VAT

      newRateSelected[roomNumber - 1] = {
        amount,
        amountWithTax,
        partnerDataID,
        rateIndex: index,
        rateName,
        roomName,
        serviceCharge,
        tax,
        vat,
      }
      const nextRoom = newRateSelected.indexOf(null) + 1
      nextRoom > 0 ? setActiveRoom(nextRoom) : null
      setRateDisplay(ratesList[index])
      setRateIndexSelected(index)
      setRateSelectedOnRooms(newRateSelected)
      !isDisplayed && toggleRef.current?.click()
    } else {
      const newRateDeselected = [...rateSelectedOnRooms]
      newRateDeselected[roomNumber - 1] = null
      setRateSelectedOnRooms(newRateDeselected)
    }
  }
  const handleCollapseChange = key => {
    if (key.length > 0) {
      setIsViewRate(false)
    } else setIsViewRate(true)
  }
  const handleModalToggle = key => {
    setTabKey(key.toString())
    handleShowRoom()
  }

  const handleShowRoom = () => {
    setShowRoomDetails(true)
  }

  const ratesSelection = ratesList.map((rateDetails, index) => {
    return index !== rateIndexSelected ? (
      <div key={index}>
        <RateSummary
          handleClick={handleClick}
          isDisplayed={false}
          paymentOptions={paymentOptions}
          priceData={priceData}
          rateDetails={rateDetails}
          rateIndex={index}
          roomDetails={roomDetails}
          selected={false}
        />
        {ratesList.length === index + 1 ? "" : <Divider />}
      </div>
    ) : null
  })

  return (
    <>
      <div
        className="book-summary-main-collapsible-wrapper"
        style={{ marginBottom: "30px" }}
      >
        <div className="booking-details-wrapper">
          <div className="main-collapsible-header-wrapper">
            <div className="main-collapsible-img-wrapper">
              <img src={photoPreview} />
              <div onClick={() => handleModalToggle(2)}>
                <PictureOutlined style={{ marginRight: "5px" }} />
                View Pictures
              </div>
            </div>
            <div>
              <div className="booking-summary-cont">
                <div className="booking-summary-details-cont booking-summary-name-detail">
                  <div className="hotel-name">{roomName}</div>
                  <Button
                    type="text"
                    className="txt-danger-btn"
                    onClick={() => handleModalToggle(1)}
                  >
                    View Room Details
                  </Button>
                </div>
              </div>
              <div className="select-instruction">
                Select from our best available rates
              </div>
              <RateSummary
                handleClick={handleClick}
                isDisplayed={true}
                paymentOptions={paymentOptions}
                priceData={priceData}
                rateDetails={rateDisplay}
                rateIndex={rateIndexSelected}
                roomDetails={roomDetails}
                selected={isSelected}
              />
            </div>
          </div>
        </div>
        <Collapse onChange={handleCollapseChange} collapsible={isCollapsible}>
          <Panel
            showArrow={false}
            header={
              <div className={`collapse-${isCollapsible} `} ref={toggleRef}>
                <span>{isViewRate ? "View all rates" : "Hide rates"}</span>{" "}
                <ArrowRightIcon />
              </div>
            }
            key="main-1"
          >
            <div style={{ display: "flex", width: "100%" }}>
              <div style={{ width: 240 }} />
              <div style={{ width: "100%" }}>{ratesSelection}</div>
            </div>
          </Panel>
        </Collapse>
        <RoomDetails
          details={roomDetails}
          onCancel={() => setShowRoomDetails(false)}
          setTabKey={setTabKey}
          tabKey={tabKey}
          visible={showRoomDetails}
        />
      </div>
    </>
  )
}
export default RoomCard
