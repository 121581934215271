import React from "react"
import { Modal } from "antd"

import "../BookingDetails.scss"
import warningIcon from "../../../assets/images/ic_report.svg"

interface PropsI {
  visible: boolean
  onCancel?: () => void
}

const AgreementDetails = ({ visible, onCancel }: PropsI) => {
  return (
    <Modal
      className="hotel-details-modal-invalid"
      visible={visible}
      footer={false}
      onCancel={onCancel}
    >
      <img src={warningIcon} alt="warning icon" />
      <div className="hotel-details-message">
        Oops! Promo code doesn't exist
      </div>
    </Modal>
  )
}

export default AgreementDetails
