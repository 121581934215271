import React from "react"
import { Divider, Modal } from "antd"

import "../BookingDetails.scss"

interface PropsI {
  details?: any
  visible: boolean
  onCancel?: () => void
}

const PromoCodeDetails = ({ details, visible, onCancel }: PropsI) => {
  const title = details?.promo_code

  return (
    <div>
      <Modal
        className="hotel-details-modal-wrapper"
        visible={visible}
        footer={false}
        onCancel={onCancel}
      >
        <h1 className="hotel-name">{title}</h1>
        <Divider />
        <div
          className="hotel-promo-description"
          dangerouslySetInnerHTML={{ __html: details?.terms_and_condition }}
        />
      </Modal>
    </div>
  )
}

export default PromoCodeDetails
