import * as React from "react"
import { CarouselProps } from "antd"

import { StyledCarousel } from "./styled"

import ChevronRightWhite from "../../assets/images/ic_chevron_right_24px_white.svg"
import ChevronLeftWhite from "../../assets/images/ic_chevron_left_24px.svg"

interface Props extends CarouselProps {
  items: unknown[]
  renderItem: any
  dots?: boolean
  slidesToShow?: number
  slidesToScroll?: number
  infinite?: boolean
  arrows?: boolean
  draggable?: boolean
  autoplay?: boolean
  autoplaySpeed?: number
}

const CustomCarousel: React.FC<Props> = ({
  items,
  renderItem,
  dots,
  slidesToShow,
  slidesToScroll,
  infinite,
  arrows,
  draggable,
  autoplay,
  autoplaySpeed,
  ...restProps
}) => {
  return (
    <StyledCarousel
      {...restProps}
      prevArrow={
        <div style={{ backgroundColor: "red" }}>
          <img src={ChevronLeftWhite} />
        </div>
      }
      nextArrow={
        <div style={{ backgroundColor: "red" }}>
          <img src={ChevronRightWhite} />
        </div>
      }
      infinite={infinite}
      arrows={arrows ? true : false}
      slidesToShow={slidesToShow ? slidesToShow : 1}
      slidesToScroll={slidesToScroll ? slidesToScroll : 1}
      dots={dots}
      draggable={draggable}
      autoplay={autoplay}
      autoplaySpeed={autoplaySpeed}
    >
      {items && items.map((item, i) => renderItem(item, i))}
    </StyledCarousel>
  )
}

export default CustomCarousel
