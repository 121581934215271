import React, { ReactNode, useEffect, useState } from "react"
import { Row, Col } from "antd"
import { FormItem, Input } from "formik-antd"

import CustomSelect, { SizeType } from "../../CustomSelect"

import "./ReviewAndPay.styled.scss"

interface Props {
  countryCodeList: any
  label: string | ReactNode
  inputName: string
  flagSelectName: string
  inputPlaceholder?: string
  flagSelectPlaceholder?: string
  flagSelectDefaultValue?: string
  size?: SizeType
}

const MobileNoSelect: React.FC<Props> = ({
  countryCodeList,
  label,
  inputName,
  flagSelectName,
  inputPlaceholder,
  flagSelectPlaceholder,
  flagSelectDefaultValue,
  size,
}) => {
  const [isPhCode, setIsPhCode] = useState(true)
  const [maxLength, setMaxLength] = useState(10)
  const [inputValue, setInputValue] = useState("")

  const handleChange = e => {
    setInputValue(e.target.value)
  }

  const handleSelect = e => {
    e === "+63" ? setIsPhCode(true) : setIsPhCode(false)
    setInputValue("")
  }

  useEffect(() => {
    isPhCode ? setMaxLength(10) : setMaxLength(30)
  }, [isPhCode])
  return (
    <>
      <div className="custom-select-input">
        <Col
          span={24}
          className="ant-form-item-label"
          style={{ paddingRight: 0, paddingLeft: 0 }}
        >
          <label className="mobile-label">{label}</label>
        </Col>
        <div>
          <Row>
            <Col span={24}>
              <FormItem name={inputName}>
                <Row>
                  <Col span={7}>
                    <CustomSelect
                      defaultValue={flagSelectDefaultValue}
                      name={flagSelectName}
                      onChange={handleSelect}
                      options={countryCodeList}
                      placeholder={flagSelectPlaceholder}
                      hasFlag
                      size={size}
                    />
                  </Col>
                  <Col span={17}>
                    <Input
                      name={inputName}
                      placeholder={inputPlaceholder}
                      size={size}
                      maxLength={maxLength}
                      value={inputValue}
                      onChange={handleChange}
                    />
                  </Col>
                </Row>
              </FormItem>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

export default MobileNoSelect
