// dummy data. will delete
import * as React from "react"
import * as yup from "yup"
import { MOBILE_NUM_PATTERN } from "../../utilities/regex"

import SampleImg from "../../assets/images/hero-banner-image.png"
import SampleImg2 from "../../assets/images/family.png"

export const overviewDescription = `
<h5>About the hotel</h5>
<p>Summit Galleria Cebu, the flagship hotel of Summit Hotels and Resorts, is the epitome of artistic and urban contemporary design. Every step taken inside the hotel will give you sublime glimpse of everything beautiful and celebrated in Cebu!</p>

<p>Envisioned as a modern structure that makes thoughtful gestures to Cebu City’s glorious culture and history, this 220-room wonder offers exquisitely designed rooms in iconic design palettes, all furnished with quality beds and linens, ultra-soft, non-allergenic pillows and premium, customized amenities for an exceptional hotel experience.</p>

<p>Summit Galleria Cebu showcases grand facilities and superb amenities, which makes it to be a unique home for work, play, and relaxation. It features a wonderful indoor pool that is a best haven rain-or-shine. The hotel houses an all-day dining restaurant, café and a bar made available to cater different tummies’ desires. Set up as a mixture of vibrant seating clusters, and game and leisure areas with foosball, darts, and a billiards table, this was designed as an imaginative social space where guests can lounge or catch up over coffee and indulge in delectable dishes among friends, family, and colleagues.</p>

<p>Moreover, the hotel is geared up to be the destination of your business meetings and events. We are teamed up with the best banquets and events team to deliver the ideal venue for your next corporate meeting, lecture, symposium, conference, art exhibition, product launch, or private party event!</p>

<p>Situated within the 4.7 hectare Robinsons Galleria Cebu lifestyle complex, Summit Galleria Cebu offers direct access to the Robinsons shopping mall and a host of international brand retail and service outlets. The hotel will have a cosmopolitan air and a sense of the city and the locality. Just two blocks from Pier 4 and 45-minutes from the airport, the hotel is conveniently poised at the corner of General Maxilom Avenue and B. Benedicto Street for utmost accessibility to all travelers.</p>

<p>Our service team can’t wait to welcome and show you why Summit Galleria Cebu is your new home in the Queen City of the South! Oh, the fun Summit staffers are going to have watching guests painstakingly decide between retail therapy in the next-door Robinsons shopping complex or staying at the hotel and simply relaxing by the 3rd floor indoor pool!</p>

`

export const amenitiesDescription = `<div>
<h5>Leisure and Wellness</h5>
<p>Have a dip rain or shine in our indoor swimming pool, or continue with your workout routine in Apex Fitness Center during your stay at the Summit.</p>

<h5>Event Spaces and Dining</h5>
<p>Summit Galleria Cebu is conducive for small to medium-sized meetings, doing business, and a lot more. Choose from our different amenities that will suit your event’s needs.</p>
</div>`

export const sampleItems = [
  {
    title: "Sinulog Suite",
    featuredImage: `${SampleImg}`,
  },
  {
    title: "Sinulog Suite",
    featuredImage: `${SampleImg2}`,
  },
]

export const validationSchema = yup.object().shape({
  first_name: yup.string().trim().required("This field is required."),
  last_name: yup.string().trim().required("This field is required."),
  email_address: yup
    .string()
    .trim()
    .email("Invalid email.")
    .required("This field is required."),
  payment: yup.string().required("This field is required."),
  mobile_no: yup
    .string()
    .required("This is a required field.")
    .when(["country_code"], {
      is: country_code => country_code === "+63",
      then: yup
        .string()
        .trim()
        .max(12, "Mobile no. must not exceed 10 characters.")
        .matches(MOBILE_NUM_PATTERN, "Invalid format."),
      otherwise: yup
        .string()
        .trim()
        .nullable()
        .matches(/^[0-9]*$/, "Invalid format."),
    }),
  country_code: yup.string().default("+63"),
  terms_condition_checked: yup
    .boolean()
    .oneOf([true], "This is a required field."),
  companions: yup.array().of(
    yup.object().shape({
      mobile_no: yup.string().when(["country_code"], {
        is: country_code => country_code === "+63",
        then: yup
          .string()
          .trim()
          .max(12, "Mobile no. must not exceed 11 characters.")
          .matches(MOBILE_NUM_PATTERN, "Invalid format."),
        otherwise: yup
          .string()
          .trim()
          .nullable()
          .matches(/^[0-9]*$/, "Invalid format."),
      }),
      country_code: yup.string().default("+63"),
    })
  ),
})

export const initialValuesReviewPay = {
  first_name: "",
  last_name: "",
  email_address: "",
  country_code: "+63",
  mobile_no: "",
  special_instructions: "",
  terms_condition_checked: false,
  newsletter_checked: false,
  payment: "",
  companions: [],
}

export const script = `if (typeof window !== "undefined"){
  window.onbeforeunload = confirmExit;

  if(window.location.pathname.substr(window.location.pathname.length - 1) !== "/"){
    var $newUrlDev = window.location.origin+window.location.pathname+'/'+window.location.search;
    if (window.history.replaceState) {
      window.history.replaceState(window.location.search, "URL Params", $newUrlDev);
    }
  }

  if(window.location.pathname.substr(window.location.pathname.length - 1) === "/"){
    var $url = window.location.pathname.substring(0, window.location.pathname.length - 1);
    var $newUrl = window.location.origin+$url+'/'+window.location.search;
    if (window.history.replaceState) {
      window.history.replaceState(window.location.search, "URL Params", $newUrl);
    }
  }

  function confirmExit(){
    return "test";
  };
}`
