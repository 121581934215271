import React, { useEffect, useState } from "react"
import { Tabs, Modal } from "antd"

import CustomCarousel from "../../CustomCarousel"
import "../BookingDetails.scss"

const { TabPane } = Tabs

interface Props {
  activeTab: string
  details?: any
  onCancel?: () => void
  setActiveTab: React.Dispatch<React.SetStateAction<string>>
  visible: boolean
}

const HotelDetails: React.FC<Props> = ({
  activeTab,
  details,
  onCancel,
  setActiveTab,
  visible,
}) => {
  const [amenities, setAmenities] = useState("")
  const [description, setDescription] = useState("")
  const [hotelPhotos, setHotelPhotos] = useState([])
  const [name, setName] = useState("")

  useEffect(() => {
    if (details) {
      setAmenities(details.facilities_and_amenities)
      setDescription(details.description)
      setHotelPhotos(details.uploaded_hotel_photos)
      setName(details.name)
    }
  }, [details])

  const handleChange = e => {
    setActiveTab(e)
  }

  return (
    <>
      <div>
        <Modal
          className="hotel-details-modal-wrapper"
          visible={visible}
          footer={false}
          onCancel={onCancel}
        >
          <h5 className="hotel-name">{name}</h5>
          <Tabs activeKey={activeTab} onChange={handleChange}>
            <TabPane tab="Overview" key="1">
              <div
                className="overview-description"
                dangerouslySetInnerHTML={{ __html: description }}
              />
            </TabPane>
            <TabPane tab="Pictures" key="2">
              <CustomCarousel
                arrows
                draggable
                infinite
                dots={false}
                items={hotelPhotos}
                renderItem={(item, i) => (
                  <div
                    key={`carousel_${i}`}
                    className="carousel-item-img-wrapper"
                  >
                    <img src={item.url} />
                    <div className="carousel-item-bottom-desc-wrapper">
                      <div className="carousel-item-title">
                        {item.photo_description}
                      </div>
                      <div className="carousel-item-length">{`${i + 1} / ${
                        hotelPhotos.length
                      }`}</div>
                    </div>
                  </div>
                )}
              />
            </TabPane>
            <TabPane tab="Amenities" key="3">
              <div
                className="amenities-description"
                dangerouslySetInnerHTML={{ __html: amenities }}
              />
            </TabPane>
          </Tabs>
        </Modal>
      </div>
    </>
  )
}

export default HotelDetails
