import { gql } from "@apollo/client"

export const BOOKING_HOTEL_INFO = gql`
  query searchHotelToBook($input: DjuboApiAvailabilityInput!) {
    searchHotelToBook(Input: $input) {
      name
      address
      app_logo
      uploaded_hotel_photos {
        url
        photo_description
      }
      mobile_no
      landline_no
      email_address
      footnote_title
      footnote_body
      tripadvisor_link
      description
      facilities_and_amenities
      payment_options {
        platform
        status
        pay_at_hotel
      }
    }
  }
`
