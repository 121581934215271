import React, { useEffect, useState } from "react"
import moment from "moment"
import { useQuery } from "@apollo/client"

import {
  InformationIcon,
  LocationIcon,
  PhoneIcon,
  MailIcon,
} from "../../utilities/icons/icons"
import { BOOKING_HOTEL_INFO } from "./graphql"
import { dateFormatApi } from "../FindaHotel/initailValue"

const BookingHeader = ({
  address,
  brandName,
  datePickerHotel,
  details,
  hotelID,
  name,
  setActiveTab,
  setDetails,
  setShowHotelDetails,
}) => {
  const [bannerColor, setBannerColor] = useState("#1d2c42")
  const [footnoteText, setFootnoteText] = useState<string>("Know More")
  const [showFootnoteBody, setShowFootnoteBody] = useState("none")

  const { data } = useQuery(BOOKING_HOTEL_INFO, {
    variables: {
      input: {
        hotelId: hotelID,
        search: `${name}`,
        checkInDate: moment(datePickerHotel?.[0]).format(dateFormatApi),
        checkOutDate: moment(datePickerHotel?.[1]).format(dateFormatApi),
      },
    },
  })

  const handleClick = () => {
    if (showFootnoteBody === "none") {
      setFootnoteText("Hide")
      setShowFootnoteBody("block")
    } else {
      setFootnoteText("Know More")
      setShowFootnoteBody("none")
    }
  }

  const handleViewDetails = () => {
    setActiveTab("1")
    setShowHotelDetails(true)
  }

  useEffect(() => {
    if (typeof data !== "undefined") {
      setDetails(data.searchHotelToBook[0])
    }
  }, [data])

  useEffect(() => {
    if (brandName?.indexOf("Go Hotel") >= 0) {
      setBannerColor("#F26F21")
    } else if (brandName?.indexOf("Grand Summit") >= 0) {
      setBannerColor("#B08300")
    } else if (brandName?.indexOf("Summit Hotel") >= 0) {
      setBannerColor("#9C1C1F")
    }
  }, [brandName])

  const footnoteBanner = details?.footnote_title ? (
    <div className="footnote-wrapper paddingpX50">
      <div className="footnote">
        <InformationIcon />{" "}
        <span className="advisory-note">
          <span>{details?.footnote_title}</span>{" "}
        </span>
        <a className="txt-white-btn" onClick={handleClick}>
          {footnoteText}
        </a>
        <div
          className="footnote-body"
          style={{ display: showFootnoteBody }}
          dangerouslySetInnerHTML={{ __html: details?.footnote_body }}
        />
      </div>
    </div>
  ) : null

  return (
    <>
      <div
        className="booking-details-header-wrapper paddingpX50"
        style={{
          backgroundColor: bannerColor,
        }}
      >
        <div className="booking-details-header-side-img-wrapper">
          {details ? (
            <div
              className="booking-details-header-side-img"
              style={{
                backgroundImage: `linear-gradient(90deg, ${bannerColor}, rgba(0,0,0,0)),url('${details.uploaded_hotel_photos[0].url}')`,
              }}
            />
          ) : null}
        </div>

        <div
          className="booking-details-header"
          style={{ opacity: details ? 1 : 0 }}
        >
          <div className="booking-details-header-img-wrapper">
            <img src={details?.app_logo} alt="brand logo img" />
          </div>
          <div className="booking-details-header-hotel">
            <div className="booking-details-header-name-reviews">
              <h4>{name}</h4>
              <div className="booking-details-header-reviews">
                <a href={details?.tripadvisor_link} target="_blank">
                  View Guest Reviews
                </a>
              </div>
            </div>
            <div className="booking-details-header-location-contact">
              <div>
                <LocationIcon /> {address}
              </div>
              <a>
                <PhoneIcon />{" "}
                {`${details?.mobile_no} / ${details?.landline_no}`}
              </a>
              <a>
                <MailIcon /> {details?.email_address}
              </a>
            </div>
            <div className="booking-details-header-button">
              <a onClick={handleViewDetails}>View Hotel Details</a>
            </div>
          </div>
        </div>
      </div>
      {footnoteBanner}
    </>
  )
}

export default BookingHeader
