import React, { useEffect, useState } from "react"
import { Divider, Modal, Tabs } from "antd"

import CustomCarousel from "../../CustomCarousel"
import "../BookingDetails.scss"

const { TabPane } = Tabs

interface PropsI {
  details: any
  onCancel?: () => void
  setTabKey: React.Dispatch<React.SetStateAction<string>>
  tabKey: string
  visible: boolean
}

const RoomDetails = ({
  details,
  onCancel,
  setTabKey,
  tabKey,
  visible,
}: PropsI) => {
  const [amenities, setAmenities] = useState<string>("")
  const [cancellationRules, setCancellationRules] = useState<string>("")
  const [description, setDescription] = useState<string>("")
  const [inclusions, setInclusions] = useState<string>("")
  const [roomPhotos, setRoomPhotos] = useState<any[]>([])
  const [name, setName] = useState<string>("")
  const [termsAndConditions, setTermsAndConditions] = useState<string>("")

  useEffect(() => {
    if (details) {
      setAmenities(details.general_inclusions)
      setCancellationRules(details.cancellation_policy)
      setDescription(details.description)
      setInclusions(details.category_inclusions)
      setRoomPhotos(details.photos)
      setName(details.room_name)
      setTermsAndConditions(details.terms_and_conditions)
    }
  }, [details])

  const handleChange = e => {
    setTabKey(e)
  }

  return (
    <div>
      <Modal
        className="hotel-details-modal-wrapper"
        visible={visible}
        footer={false}
        onCancel={onCancel}
      >
        <h1 className="hotel-name">{name}</h1>
        <Tabs activeKey={tabKey} onChange={handleChange}>
          <TabPane tab="Inclusions" key="1">
            <h3 className="rate-header">Description</h3>
            <p className="overview-description">{description}</p>
            <h3 className="rate-header">Amenities & Inclusions</h3>
            <div
              className="overview-description"
              dangerouslySetInnerHTML={{ __html: amenities }}
            />
            <div
              className="overview-description"
              dangerouslySetInnerHTML={{ __html: inclusions }}
            />
          </TabPane>
          <TabPane tab="Pictures" key="2">
            <CustomCarousel
              arrows
              draggable
              infinite
              dots={false}
              items={roomPhotos}
              renderItem={(item, i) => (
                <div
                  key={`carousel_${i}`}
                  className="carousel-item-img-wrapper"
                >
                  <img src={item} />
                  <div className="carousel-item-bottom-desc-wrapper">
                    <div className="carousel-item-length">{`${i + 1} / ${
                      roomPhotos.length
                    }`}</div>
                  </div>
                </div>
              )}
            />
          </TabPane>
          <TabPane tab="Terms & Conditions / Cancellation Policy" key="3">
            <h3 className="rate-header">Terms & Conditions</h3>
            <div
              className="amenities-description"
              dangerouslySetInnerHTML={{ __html: termsAndConditions }}
            />
            {cancellationRules ? (
              <>
                <h3 className="rate-header">Cancellation Rules</h3>
                <ul className="rules-list">{cancellationRules}</ul>
              </>
            ) : null}
          </TabPane>
        </Tabs>
        <Divider />
      </Modal>
    </div>
  )
}

export default RoomDetails
