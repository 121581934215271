import { gql } from "@apollo/client"

export const BOOKING_ROOMSANDRATES = gql`
  query getAvailableRooms($input: DjuboApiAvailabilityInput!) {
    getAvailableRooms(Input: $input) {
      name
      address
      terms_and_conditions
      party_information {
        room_id
        adults
        children
      }
      rooms {
        persistent_room_code
        room_name
        description
        photos
        room_rates {
          partner_data
          room_type_key
          rate_plan_key
          rate_plans {
            type
            name
            description
            meal_plan {
              standard
              custom
            }
            cancellation_policy {
              cancellation_summary {
                refundable
                unstructured_cancellation_text
                cancellation_deadline
              }
              cancellation_rules {
                start_datetime
                end_datetime
                percent_fee {
                  amount
                }
              }
            }
            pkg_terms_condition {
              text
              override_terms_condition
            }
            inclusion {
              text
              override_inclusion
            }
          }
          rooms_remaining
          price_per_room {
            room_id
            totalPrice
            totalTax
            priceWithTax
            totalPricePerNight
            totalTaxPerNight
            pricePerNightWithTax
            tax_breakdown {
              VAT
              service_charge
            }
          }
        }
      }
    }
    getAddOns(Input: $input) {
      addOns {
        id
        name
        description
        net_rates
        images {
          image_paths
        }
        tax
        tax_breakup {
          component_name
          amount
        }
      }
    }
  }
`

export const ROOM_DETAILS = gql`
  query getRoomDetails($input: DjuboApiAvailabilityInput!) {
    getRoomDetails(Input: $input) {
      persistent_room_code
      room_name
      description
      photos
      general_inclusions
      category_inclusions
      terms_and_conditions
      cancellation_policy
    }
  }
`
export const GET_PROMO_CODES = gql`
  query getApplicableDealsOnBooking($input: DjuboApiDealsInput!) {
    getApplicableDealsOnBooking(Input: $input) {
      name
      deals {
        deal_info {
          discount_description
          p_v
          deal_type
          discount_mode_figure
          discount_mode {
            discount_code
            discount_text
          }
          applicable_on_pay_at_hotel
        }
        total_net_price_before_discount
        total_net_price_after_discount
        total_tax_before_discount
        total_tax_after_discount
        total_deal_discount
        promo_code
        terms_and_condition
      }
    }
  }
`

export const VALIDATE_PROMO_CODE = gql`
  query validatePromoCode($input: DjuboApiDealsInput!) {
    validatePromoCode(Input: $input) {
      name
      validatedDeal {
        deal_info {
          discount_description
          p_v
          deal_type
          discount_mode_figure
          discount_mode {
            discount_code
            discount_text
          }
          applicable_on_pay_at_hotel
        }
        validated
        total_net_price_before_discount
        total_net_price_after_discount
        total_tax_before_discount
        total_tax_after_discount
        total_deal_discount
        promo_code
        terms_and_condition
        rooms {
          room_id
          rates {
            date
            breakup {
              room {
                taxBreakUp {
                  component_name
                  price
                }
              }
              meal {
                taxBreakUp {
                  component_name
                  price
                }
              }
              pkg {
                taxBreakUp {
                  component_name
                  price
                }
              }
            }
          }
        }
      }
    }
  }
`

export const BOOKING_SUBMIT = gql`
  mutation bookingSubmit($Input: CreateBookingInput!) {
    bookingSubmit(Input: $Input) {
      reference_no
      reservation_id
      status
    }
  }
`
