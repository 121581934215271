import React, { useState, useEffect } from "react"
import { Button, Col, Row } from "antd"
import { PlusCircleFilled } from "@ant-design/icons"
import { Checkbox, Form, FormItem, Input } from "formik-antd"
import { FieldArray } from "formik"

import AgreementDetails from "../Modals/AgreementDetails"
import CustomSelect from "../../CustomSelect"
import MobileNoSelect from "./MobileNoSelect"
import "./ReviewAndPay.styled.scss"
import { RoomsI } from "../../FindaHotel/interface"

interface Props {
  formikBag?: any
  countryCodeList?: any
  paymentOptions: any
  setMessage: React.Dispatch<React.SetStateAction<string>>
  setTitle: React.Dispatch<React.SetStateAction<string>>
  setSelectedPaymentOption?: React.Dispatch<React.SetStateAction<string>>
  selectedPaymentOption?: string
  termsAndConditions: string
  paymentsNotApplicable?: string[]
  rooms: RoomsI[]
}

const renderCompanions = ({
  isMaxCompanion,
  event,
  values,
  countryCodesArr,
}) => {
  const addCompanion = () => {
    event.push({
      first_name: "",
      last_name: "",
      email_address: "",
      mobile_no: "",
      country_code: "+63",
    })
  }

  return (
    <>
      {values.companions &&
        values.companions.map((item, key) => (
          <div className="form-wrapper">
            <Row justify="space-between" align="top">
              <Col>
                <h5 className="form-title subtitle-1">
                  Companion Details # {key + 1}
                  <span className="body-2" style={{ marginLeft: 15 }}>
                    This field is optional.
                  </span>
                </h5>
              </Col>
              <Col>
                <a
                  className="remove-btn"
                  onClick={() => {
                    event.remove(key)
                  }}
                >
                  Remove
                </a>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Row gutter={20}>
                  <Col span={12}>
                    <FormItem
                      label={"First Name"}
                      name={`companions[${key}].first_name`}
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                    >
                      <Input
                        name={`companions[${key}].first_name`}
                        placeholder="Enter First Name"
                        size="large"
                      />
                    </FormItem>
                  </Col>
                  <Col span={12}>
                    <FormItem
                      label={"Last Name"}
                      name={`companions[${key}].last_name`}
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                    >
                      <Input
                        name={`companions[${key}].last_name`}
                        placeholder="Enter Last Name"
                        size="large"
                      />
                    </FormItem>
                  </Col>
                  <Col span={12}>
                    <FormItem
                      label={"Email Address"}
                      name={`companions[${key}].email`}
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                    >
                      <Input
                        name={`companions[${key}].email`}
                        placeholder="Enter Email"
                        size="large"
                      />
                    </FormItem>
                  </Col>
                  <Col span={12}>
                    <MobileNoSelect
                      label="Mobile No."
                      inputName={`companions[${key}].phone_number`}
                      flagSelectName={`companions[${key}].country_code`}
                      countryCodeList={countryCodesArr}
                      inputPlaceholder="e.g 9172980102"
                      flagSelectPlaceholder="+63"
                      flagSelectDefaultValue="+63"
                      size="large"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        ))}
      {!isMaxCompanion && (
        <div className="companion-btn-wrapper">
          <Button size="large" onClick={() => addCompanion()}>
            <PlusCircleFilled />
            Add Companion Details{" "}
            <span className="optional-span blue-gray">(Optional)</span>
          </Button>
        </div>
      )}
    </>
  )
}

const ReviewAndPay: React.FC<Props> = ({
  countryCodeList,
  formikBag,
  paymentOptions,
  setMessage,
  setTitle,
  setSelectedPaymentOption,
  selectedPaymentOption,
  termsAndConditions,
  paymentsNotApplicable,
  rooms,
}) => {
  const { values, submitForm } = formikBag

  const [isMaxCompanion, setIsMaxCompanion] = useState(false)

  useEffect(() => {
    const companionsLimit =
      rooms.reduce((total: number, current: RoomsI) => {
        return total + (current.adult + current.children)
      }, 0) - 1
    if (companionsLimit > values.companions.length) {
      return
    } else {
      return setIsMaxCompanion(true)
    }
  }, [values.companions])

  const payOptions = [
    paymentOptions?.pay_at_hotel === "ENABLED"
      ? { label: "Pay at Hotel", value: "Pay at Hotel" }
      : null,
    paymentOptions?.status === "ENABLED"
      ? {
          label: "Pay Online via iPay88",
          value: "Pay Online via iPay88",
        }
      : null,
  ]
    .filter(item => !paymentsNotApplicable?.includes(item?.value || ""))
    .filter(item => item)

  const [countryCodesArr, setCountryCodesArr] = useState<any[]>([])
  const [showAgreementModal, setShowAgreementModal] = useState<boolean>(false)

  useEffect(() => {
    let arrayList: any = []

    if (countryCodeList) {
      const countryCodeArr = countryCodeList
      countryCodeArr.forEach(item => {
        if (item.dial_code) {
          arrayList.push({
            value: item.dial_code,
            label: item.dial_code,
            flag: item.flag,
          })
        }
      })
      setCountryCodesArr(arrayList)
    }
  }, [countryCodeList])

  const handleChange = e => {
    setSelectedPaymentOption && setSelectedPaymentOption(e)
    if (e === "Pay at Hotel") {
      setMessage(
        "You are now being redirected to a confirmation page to complete your reservation"
      )
      setTitle("Proceed to Booking Confirmation")
    } else {
      setMessage(
        "You are now being redirected to an external payment gateway to complete your payment"
      )
      setTitle("Proceed to Payment")
    }
  }

  return (
    <>
      <Form>
        <div className="review-pay-wrapper">
          <Col>
            <h5 className="review-pay-title subtitle-1">
              Enter Guest/s Details{" "}
            </h5>
          </Col>
          <Col>
            <span style={{ color: "#174782" }}>
              * Requested fields, do not leave this blank.
            </span>
          </Col>
          <div className="form-wrapper">
            <h5 className="form-title subtitle-1">Main Guest Details</h5>
            <Row>
              <Col span={24}>
                <Row gutter={20}>
                  <Col xs={23} xl={12}>
                    <FormItem
                      label={"* First Name"}
                      name={"first_name"}
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                    >
                      <Input
                        name="first_name"
                        placeholder="Enter First Name"
                        size="large"
                      />
                    </FormItem>
                  </Col>
                  <Col xs={23} xl={12}>
                    <FormItem
                      label={"* Last Name"}
                      name={"last_name"}
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                    >
                      <Input
                        name="last_name"
                        placeholder="Enter Last Name"
                        size="large"
                      />
                    </FormItem>
                  </Col>
                  <Col xs={23} xl={12}>
                    <FormItem
                      label={"* Email Address"}
                      name={"email_address"}
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                    >
                      <Input
                        name="email_address"
                        placeholder="Enter Email"
                        size="large"
                      />
                    </FormItem>
                  </Col>
                  <Col xs={23} xl={12}>
                    <MobileNoSelect
                      label="* Mobile No."
                      inputName="mobile_no"
                      flagSelectName="country_code"
                      countryCodeList={countryCodesArr}
                      inputPlaceholder="e.g 9172980102"
                      flagSelectPlaceholder="+63"
                      flagSelectDefaultValue="+63"
                      size="large"
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <FormItem
                  label={"Special Instructions"}
                  name={"special_instructions"}
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                >
                  <Input.TextArea
                    name="special_instructions"
                    placeholder="e.g Flight Details, Arrival time, etc"
                    showCount
                    maxLength={150}
                  />
                </FormItem>
              </Col>
              <Col xs={24}>
                <FormItem
                  name="terms_condition_checked"
                  className="tc-checkbox"
                >
                  <Checkbox name="terms_condition_checked">
                    * I have read, understood, and agreed to Robinsons Hotels &
                    Resorts'{" "}
                    <span
                      className="a-link secondary"
                      onClick={() => setShowAgreementModal(true)}
                    >
                      Terms and Conditions
                    </span>
                    , and{" "}
                    <a
                      target="_blank"
                      href="/data-privacy-and-cookies-policy"
                      className="a-link secondary"
                    >
                      Data Privacy and Cookies Policy
                    </a>
                    .
                  </Checkbox>
                </FormItem>
              </Col>
              <Col span={24}>
                <Checkbox name="newsletter_checked">
                  Be the first to know about special offers, exclusive deals,
                  and property updates from Robinsons Hotels & Resorts
                </Checkbox>
              </Col>
            </Row>
          </div>
          <FieldArray
            name="companions"
            render={event =>
              renderCompanions({
                isMaxCompanion,
                event,
                values,
                countryCodesArr,
              })
            }
          />
          <div className="form-wrapper">
            <h5 className="form-title subtitle-1">Payment Details</h5>
            <Row>
              <Col span={24}>
                <FormItem
                  label={"* Payment Option"}
                  name={"payment"}
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                >
                  <CustomSelect
                    name="payment"
                    placeholder="Select Payment Option"
                    options={payOptions}
                    size="large"
                    onChange={handleChange}
                    value={selectedPaymentOption}
                  />
                </FormItem>
              </Col>
            </Row>
          </div>

          <Button
            type="primary"
            className="button-default-primary"
            size="large"
            style={{ width: "100%", height: 50, marginBottom: "2em" }}
            onClick={submitForm}
          >
            Book Reservation
          </Button>
        </div>
      </Form>
      <AgreementDetails
        details={termsAndConditions}
        visible={showAgreementModal}
        onCancel={() => setShowAgreementModal(false)}
      />
    </>
  )
}

export default ReviewAndPay
