import React, { useEffect, useState } from "react"

import MainLayout from "../../Layouts/layoutLanding"
import Seo from "../../components/seo"
import HotelDetails from "../../components/BookingDetails/Modals/HotelDetails"
import BookingContent from "../../components/BookingDetails/BookingContent"
import { RoomsI } from "../../components/FindaHotel/interface"
import { roomsCount } from "../../components/FindaHotel/initailValue"
import { HotelI } from "../../interface"
import { STATUS } from "../../enum"

import "./BookingDetails.scss"

interface PhotoDetailsI {
  url: string
  photo_description: string
}

interface DetailsI {
  name: string
  address: string
  app_logo: string
  uploaded_hotel_photos: Array<PhotoDetailsI>
  mobile_no: string
  landline_no: string
  email_address: string
  footnote_title: string
  footnote_body: string
  tripadvisor_link: string
}

const BookingDetails = ({ pageContext }) => {
  const [activeTab, setActiveTab] = useState("1")
  const [details, setDetails] = useState<DetailsI>()
  const [showHotelDetails, setShowHotelDetails] = useState(false)
  const [datePickerHotel, setDatePickerHotel] = useState(null)
  const [rooms, setRooms] = useState<RoomsI[]>(roomsCount)
  const [hotel, setHotel] = useState<HotelI>()
  const [getHotels, setGetHotels] = useState<HotelI[]>([])

  const { brands, hotels, countryCodes } = pageContext || {
    brands: [],
    hotels: [],
    countryCodes: [],
  }

  useEffect(() => {
    setGetHotels(hotels)
    //Booking page data
    if (typeof window !== "undefined") {
      const params = new URLSearchParams(window.location.search)
      if (params.get("hotel")) {
        const hotelDetails = JSON.parse(JSON.stringify(params.get("hotel")))

        setRooms(JSON.parse(JSON.parse(hotelDetails).rooms))
        setDatePickerHotel(JSON.parse(JSON.parse(hotelDetails).date))
        //Booking page data
        const parts = window.location.pathname.split("/")
        const lastSegment = parts.pop() || parts.pop()

        setHotel(
          getHotels.filter(
            hotel =>
              hotel.slug === lastSegment &&
              hotel.brand.status === STATUS.PUBLISHED &&
              hotel.partner_hotel_code !== null
          )[0]
        )
      }
    }
  }, [getHotels])

  return (
    <>
      {datePickerHotel !== null && (
        <MainLayout title="booking inside-pages" isBookingLayout>
          <Seo title="Booking Details" lang="en" />
          <BookingContent
            brandName={hotel?.brand.name}
            dataGraphql={pageContext}
            datePickerHotel={datePickerHotel}
            details={details}
            rooms={rooms}
            setActiveTab={setActiveTab}
            setDetails={setDetails}
            setShowHotelDetails={setShowHotelDetails}
            hotelID={hotel?.id}
          />
          <HotelDetails
            activeTab={activeTab}
            details={details}
            onCancel={() => setShowHotelDetails(false)}
            setActiveTab={setActiveTab}
            visible={showHotelDetails}
          />
        </MainLayout>
      )}
    </>
  )
}

export default BookingDetails
