import React, { useState, useEffect } from "react"
import { Button, Col, Input, Radio, Row, Space, Spin } from "antd"
import { useQuery } from "@apollo/client"
import { CloseCircleFilled } from "@ant-design/icons"

import PromoCodeDetails from "./Modals/PromoCodeDetails"
import PromoInvalid from "./Modals/PromoInvalid"

import { VALIDATE_PROMO_CODE } from "./graphql"
import { currencyFormatter } from "../../utilities/format-currency"

interface PromoFormProps {
  isPromoValidated: any
  dayCount: any
  promoCodeInput: any
  promoCodesQuery: any
  rateSelectedOnRooms: any
  setDiscountAmount: any
  setDiscountedRoomsTax: any
  setIsPromoValidated: any
  setPromoSubmit: any
  setRoomsAndRatesTaxOnly: any
  setSelectedPromo?: React.Dispatch<React.SetStateAction<Object>>
}
const PromoForm: React.FC<PromoFormProps> = ({
  isPromoValidated,
  dayCount,
  promoCodeInput,
  promoCodesQuery,
  rateSelectedOnRooms,
  setDiscountAmount,
  setDiscountedRoomsTax,
  setIsPromoValidated,
  setPromoSubmit,
  setRoomsAndRatesTaxOnly,
  setSelectedPromo,
}) => {
  const [promoApplied, setPromoApplied] = useState<string>("")
  const [promoCodeDetails, setPromoCodeDetails] = useState<object | null>(null)
  const [promoCodeValidated, setPromoCodeValidated] = useState<string>("")
  const [promoList, setPromoList] = useState<any[] | null>(null)
  const [promoInput, setPromoInput] = useState<string>("")
  const [showPromoCodeDetails, setShowPromoCodeDetails] =
    useState<boolean>(false)
  const [showPromoInvalid, setShowPromoInvalid] = useState<boolean>(false)

  const { data, loading } = useQuery(VALIDATE_PROMO_CODE, {
    variables: {
      input: { ...promoCodeInput, promo_code: promoApplied },
    },
  })

  const removePromoCode = () => {
    setDiscountAmount(0)
    setIsPromoValidated(false)
    setPromoCodeValidated("")
    setPromoSubmit(null)
    setRoomsAndRatesTaxOnly(
      rateSelectedOnRooms.reduce((total, value) => {
        const taxPrice = value ? value.tax : 0
        return total + taxPrice
      }, 0)
    )
  }

  const handlePromoApply = () => {
    setPromoApplied(promoInput)
  }

  const handlePromoCode = promo => {
    setPromoCodeDetails(promo)
    setShowPromoCodeDetails(true)
  }

  const handlePromoInput = event => {
    setPromoInput(event.target.value)
  }

  const refetchPromoQuery = () => {
    promoCodesQuery.refetch
  }

  useEffect(() => {
    removePromoCode()
    if (typeof promoCodesQuery.data !== "undefined") {
      setPromoList(promoCodesQuery.data.getApplicableDealsOnBooking.deals)
    } else {
      setPromoList(null)
    }
  }, [promoCodesQuery])

  useEffect(() => {
    if (typeof data !== "undefined") {
      const {
        deal_info,
        rooms,
        total_deal_discount,
        total_tax_after_discount,
        validated,
      } = data.validatePromoCode.validatedDeal

      if (validated) {
        const newPromoSubmit = {
          promo_code: promoApplied,
          p_v: deal_info.p_v,
          deal_type: deal_info.deal_type,
        }

        const newRoomsTax = rooms.map(data => {
          const { meal, pkg, room } = data.rates[0].breakup
          const vatTotal: number =
            (meal.taxBreakUp[1]?.price || 0) +
            (pkg.taxBreakUp[1]?.price || 0) +
            (room.taxBreakUp[1]?.price || 0)

          const serviceChargeTotal: number =
            (meal.taxBreakUp[0]?.price || 0) +
            (pkg.taxBreakUp[0]?.price || 0) +
            (room.taxBreakUp[0]?.price || 0)

          return {
            vat: vatTotal * dayCount,
            serviceCharge: serviceChargeTotal * dayCount,
          }
        })

        const selectedPromoDetails = promoList?.filter(
          promo => promo.promo_code === promoInput
        )
        
        setSelectedPromo && setSelectedPromo(selectedPromoDetails ? selectedPromoDetails[0] : {})
        setDiscountAmount(total_deal_discount)
        setDiscountedRoomsTax(newRoomsTax)
        setIsPromoValidated(true)
        setPromoCodeValidated(promoInput)
        setPromoSubmit(newPromoSubmit)
        setRoomsAndRatesTaxOnly(total_tax_after_discount)
      } else {
        if (promoInput !== "") {
          setShowPromoInvalid(true)
        }
        setDiscountedRoomsTax(null)
        removePromoCode()
        setIsPromoValidated(false)
        setPromoSubmit(null)
      }
    }
  }, [data])

  useEffect(() => {
    if (!isPromoValidated && promoCodeValidated === "") {
      setPromoInput("")
    }
  }, [isPromoValidated])

  useEffect(() => {
    if (promoInput === "") {
      handlePromoApply()
    }
  }, [promoInput])

  useEffect(() => {
    setDiscountedRoomsTax(null)
    setPromoInput("")
    removePromoCode()
    refetchPromoQuery()
  }, [rateSelectedOnRooms])

  const promoSummary = (
    <>
      <p>Discount:</p>
      <Radio.Group onChange={handlePromoInput} value={promoInput}>
        <Space direction="vertical">
          {promoList &&
            promoList.map((promo, index) => {
              const { discount_mode_figure, discount_mode } = promo.deal_info
              let discountValue

              if (discount_mode.discount_code === 2) {
                discountValue = `${currencyFormatter(discount_mode_figure, {
                  significantDigits: 2,
                  symbol: "Php",
                })} `
              } else if (discount_mode.discount_code === 1) {
                discountValue = `${discount_mode_figure}% `
              }
              return (
                <Radio key={index} value={promo.promo_code}>
                  Use{" "}
                  <span
                    className="promo-code"
                    onClick={() => handlePromoCode(promo)}
                  >
                    {promo.promo_code}
                  </span>{" "}
                  to avail {discountValue} discount
                </Radio>
              )
            })}
        </Space>
      </Radio.Group>
    </>
  )

  return (
    <Spin spinning={promoCodesQuery.loading || loading}>
      {promoList ? promoSummary : null}
      <Row gutter={[8, 0]} style={{ margin: "20px 0" }}>
        <Col span={16}>
          <Input
            placeholder="Have a promo code? Enter here"
            value={promoInput}
            onChange={e => handlePromoInput(e)}
            size="large"
          />
        </Col>
        <Col span={8}>
          <Button
            type="primary"
            size="large"
            style={{ width: "100%" }}
            onClick={handlePromoApply}
            disabled={loading}
          >
            Apply
          </Button>
        </Col>
      </Row>
      <div className="booking-summary-details-cont">
        <div className="booking-summary-variable">Promo Code Applied</div>
        {isPromoValidated ? (
          <span>
            {promoCodeValidated} <CloseCircleFilled onClick={removePromoCode} />
          </span>
        ) : (
          <span className="booking-summary-none">
            No entered/selected code yet
          </span>
        )}
      </div>
      <PromoCodeDetails
        details={promoCodeDetails}
        onCancel={() => setShowPromoCodeDetails(false)}
        visible={showPromoCodeDetails}
      />
      <PromoInvalid
        onCancel={() => setShowPromoInvalid(false)}
        visible={showPromoInvalid}
      />
    </Spin>
  )
}

export default PromoForm
